import {
    Injectable
} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class QuestionsService {
    questionsList: any;
    constructor() {
        this.questionsList = JSON.parse(localStorage.org_data)["questionsList"];
        let i;
        for (i = 0; i < this.questionsList.length; i += 1) {
            if (!this.questionsList[i].archived) {
                this.questionsList[i].archived = false;
            }
        }
        this.questionsList = this.questionsList.filter((question) => {
            return question.archived.toString().indexOf('false')> -1;
        });
    }

    getAllQuestions() {
        return new Promise((resolve, reject) => {
            resolve(this.questionsList);
        });
    }

    questionLength() {
        return new Promise((resolve, reject) => {
            resolve(this.questionsList.length);
        });
    }

    reloadQuestions() {
        this.questionsList = JSON.parse(localStorage.org_data)["questionsList"];
        let i;
        for (i = 0; i < this.questionsList.length; i += 1) {
            if (!this.questionsList[i].archived) {
                this.questionsList[i].archived = false;
            }
        }
        this.questionsList = this.questionsList.filter((question) => {
            return question.archived.toString().indexOf('false')> -1;
        });
    }

}
