import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class WorkflowsService {
    workflows: any;
    constructor() {
        this.workflows = JSON.parse(localStorage.org_data)["workflowsList"];
        let i;
        for (i = 0; i < this.workflows.length; i += 1) {
            if (!this.workflows[i].archived) {
                this.workflows[i].archived = false;
            }
        }
        this.workflows = this.workflows.filter((workflow) => {
            return workflow.archived.toString().indexOf('false')> -1;
        });
    }

    getAllWorkflows() {
        return new Promise((resolve, reject) => {
            resolve(this.workflows);
        });
    }

    getWorkflowById(id: string) {
        return new Promise((resolve, reject) => {
            let i = 0;
            for (i = 0; i < this.workflows.length; i += 1) {
                if (id === this.workflows[i].id) {
                    resolve(this.workflows[i]);
                }
            }
        });
    }

    reloadWorkflows() {
        this.workflows = JSON.parse(localStorage.org_data)["workflowsList"];
        let i;
        for (i = 0; i < this.workflows.length; i += 1) {
            if (!this.workflows[i].archived) {
                this.workflows[i].archived = false;
            }
        }
        this.workflows = this.workflows.filter((workflow) => {
            return workflow.archived.toString().indexOf('false')> -1;
        });
    }

}
