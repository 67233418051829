import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { AlertController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class UpdateService {

    constructor(private swUpdate: SwUpdate, public alertController: AlertController) {
        console.log("SW init");
        this.swUpdate.activateUpdate();
    }
}
