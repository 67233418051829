import {
    Injectable
} from '@angular/core';
import { resolve } from 'url';

import { LogService } from './log.service';
import { v4 as uuid } from 'uuid';

@Injectable({
    providedIn: 'root'
})
export class MembersService {
    usersList: any;
    memberGenders: any;
    memberOccupations: any;
    memberEducations: any;
    memberCustomFields: any;
    members: any;
    locationsHierarchy: any;
    levelsHierarchy: any;
    todos: any;
    workflows: any;
    oneToOnes: any;
    otherLanguages: any;
    log: any;
    user: any;
    myId: any;
    logs: any;
    pushLogs: any;
    myPermissions: any;

    constructor(public logService: LogService) {
        let i;
        this.myId = JSON.parse(localStorage.org_data)["myId"];
        this.myPermissions = JSON.parse(localStorage.org_data)["myPermissions"];

        this.members = JSON.parse(localStorage.org_data)["membersList"];
        for (i = 0; i < this.members.length; i += 1) {
            if (!this.members[i].archived) {
                this.members[i].archived = false;
            }
            this.members[i].dirty = false;
        }

        this.usersList = JSON.parse(localStorage.org_data)["usersList"];

        this.memberGenders = JSON.parse(localStorage.org_data)["memberGenders"];
        for (i = 0; i < this.memberGenders.length; i += 1) {
            if (!this.memberGenders[i].archived) {
                this.memberGenders[i].archived = false;
            }
        }

        this.memberGenders = this.memberGenders.filter((gender) => {
            return gender.archived.toString().indexOf('false')> -1;
        });

        this.memberOccupations = JSON.parse(localStorage.org_data)["memberOccupations"];
        for (i = 0; i < this.memberOccupations.length; i += 1) {
            if (!this.memberOccupations[i].archived) {
                this.memberOccupations[i].archived = false;
            }
        }

        this.memberOccupations = this.memberOccupations.filter((occupation) => {
            return occupation.archived.toString().indexOf('false')> -1;
        });

        this.memberEducations = JSON.parse(localStorage.org_data)["memberEducations"];
        for (i = 0; i < this.memberEducations.length; i += 1) {
            if (!this.memberEducations[i].archived) {
                this.memberEducations[i].archived = false;
            }
        }

        this.memberEducations = this.memberEducations.filter((education) => {
            return education.archived.toString().indexOf('false')> -1;
        });

        this.memberCustomFields = JSON.parse(localStorage.org_data)["memberCustomFields"];
        for (i = 0; i < this.memberCustomFields.length; i += 1) {
            if (!this.memberCustomFields[i].archived) {
                this.memberCustomFields[i].archived = false;
            }
        }

        this.memberCustomFields = this.memberCustomFields.filter((field) => {
            return field.archived.toString().indexOf('false')> -1;
        });

        this.locationsHierarchy = JSON.parse(localStorage.org_data)["locationsHierarchy"];
        this.levelsHierarchy = JSON.parse(localStorage.org_data)["levelsHierarchy"];

        this.todos = JSON.parse(localStorage.org_data)["todos"];
        for (i = 0; i < this.todos.length; i += 1) {
            if (!this.todos[i].archived) {
                this.todos[i].archived = false;
            }
            this.todos[i].dirty = false;
        }

        this.workflows = JSON.parse(localStorage.org_data)["workflows"];
        for (i = 0; i < this.workflows.length; i += 1) {
            if (!this.workflows[i].archived) {
                this.workflows[i].archived = false;
            }
            this.workflows[i].dirty = false;
        }

        this.oneToOnes = JSON.parse(localStorage.org_data)["oneToOnes"];
        for (i = 0; i < this.oneToOnes.length; i += 1) {
            if (!this.oneToOnes[i].archived) {
                this.oneToOnes[i].archived = false;
            }
            this.oneToOnes[i].dirty = false;
        }

        this.logs = JSON.parse(localStorage.org_data)["logs"];
        for (i = 0; i < this.logs.length; i += 1) {
            if (!this.logs[i].archived) {
                this.logs[i].archived = false;
            }
            this.logs[i].dirty = false;
        }

        this.pushLogs = JSON.parse(localStorage.org_data)["pushLogs"];
        for (i = 0; i < this.pushLogs.length; i += 1) {
            if (!this.pushLogs[i].archived) {
                this.pushLogs[i].archived = false;
            }
            this.pushLogs[i].dirty = false;
        }

        this.otherLanguages = JSON.parse(localStorage.org_data)["otherLanguages"];

        this.user = this.usersList.filter((user) => {
            return user.id.indexOf(this.myId) > -1;
        })[0];
    }

    writeLog(activity, details, verbose, id) {
        this.log = {
            "activity" : activity,
            "details" : details,
            "id" : uuid(),
            "timestamp" : new Date().toISOString(),
            "verbose" : verbose,
            "user" : this.myId,
            "dirty": true,
            "payload": {
                "id": id
            }
        };
        this.logService.addLog(this.log);
    }

    addMember(data) {
        return new Promise((resolve, reject) => {
            this.members.push(data);
            let temp = JSON.parse(localStorage.org_data);
            temp["membersList"] = this.members;
            localStorage.setItem("org_data", JSON.stringify(temp));
            this.writeLog("Member", "Created Member", "Created member - " + data.name, data.id);
            resolve();
        });
    }

    getAllMembers() {
        return new Promise((resolve, reject) => {
            let res = this.members.filter((member) => {
                return member.site.indexOf(this.user.location) > -1 &&
                member.archived.toString().indexOf('false') > -1;
            });
            resolve(res);
        });
    }

    getMemberById(id: string) {
        return new Promise((resolve, reject) => {
            let member = this.members.filter((member) => {
                return member.id.indexOf(id) > -1;
            });
            resolve(member[0]);
        });
    }

    updateMemberMetTime(id) {
        return new Promise((resolve, reject) => {
            let i = 0;
            for (i = 0; i < this.members.length; i += 1) {
                if (id === this.members[i].id) {
                    this.members[i].lastMeetTime = new Date();
                    let temp = JSON.parse(localStorage.org_data);
                    temp["membersList"] = this.members;
                    localStorage.setItem("org_data", JSON.stringify(temp));
                    this.writeLog("Member", "Updated member", "Updated last met time - "  + this.members[i].name, this.members[i].id);
                    resolve();
                }
            }
        });
    }

    updateMember(data) {
        return new Promise((resolve, reject) => {
            let i = 0;
            for (i = 0; i < this.members.length; i += 1) {
                if (data.id === this.members[i].id) {
                    this.members[i] = data;
                    this.members[i].dirty = true;
                    let temp = JSON.parse(localStorage.org_data);
                    temp["membersList"] = this.members;
                    localStorage.setItem("org_data", JSON.stringify(temp));
                    this.writeLog("Member", "Updated member", "Updated details - " + data.name, data.id);
                    resolve();
                }
            }
        });
    }

    deleteMember(id) {
        return new Promise((resolve, reject) => {
            let i = 0;
            for (i = 0; i < this.members.length; i += 1) {
                if (id === this.members[i].id) {
                    this.members[i].archived = true;
                    this.members[i].dirty = true;
                    let temp = JSON.parse(localStorage.org_data);
                    temp["membersList"] = this.members;
                    localStorage.setItem("org_data", JSON.stringify(temp));
                    this.writeLog("Member", "Archived member", "Archived - " + this.members[i].name, this.members[i].id);
                    resolve();
                }
            }
        });
    }

    getGenderOptions() {
        return new Promise((resolve, reject) => {
            resolve(this.memberGenders);
        });
    }

    getOccupationOptions() {
        return new Promise((resolve, reject) => {
            resolve(this.memberOccupations);
        });
    }

    getEducationOptions() {
        return new Promise((resolve, reject) => {
            resolve(this.memberEducations);
        });
    }

    getLanguageOptions() {
        return new Promise((resolve, reject) => {
            resolve(this.otherLanguages);
        });
    }

    getMemberCustomFields() {
        return new Promise((resolve, reject) => {
            resolve(this.memberCustomFields);
        });
    }

    getSiteOptions() {
        return new Promise((resolve, reject) => {
            resolve(this.getAllLeafLocations(this.levelsHierarchy, this.locationsHierarchy));
        });
    }

    fetchLeafLocations(parentName, location) {
        const currentLocation = [{
            id: location.id,
            name: location.name,
            parentName: parentName,
            archived: location.archived
        }];

        if (!location.children || location.children.length === 0) {
            return currentLocation;
        }

        const leavesOfChildren = location.children.map(childLocation => {
            return this.fetchLeafLocations(location.name, childLocation);
        });

        return [].concat.apply([], leavesOfChildren);
    }

    getAllLeafLocations(levelsHierarchy, locationsHierarchy) {
        const locationsArray = levelsHierarchy.map(level => {
            return locationsHierarchy[level.id].map(location => {
                return {
                    ...location,
                    parentName: level.name
                };
            });
        });

        const leafLocations = [].concat.apply([], locationsArray).map(topLocation => {
            return this.fetchLeafLocations(topLocation.parentName, topLocation);
        });

        return [].concat.apply([], leafLocations);
    }

    getMyProfile() {
        return new Promise((resolve, reject) => {
            let profile = this.usersList.filter((user) => {
                return user.id.indexOf(this.myId) > -1;
            });
            resolve(profile[0]);
        });
    }

    updateMyProfile(data) {
        return new Promise((resolve, reject) => {
            this.usersList.filter((user) => {
                return user.id.indexOf(JSON.stringify(data.id)) > -1;
            })[0] = data;
            let temp = JSON.parse(localStorage.org_data);
            temp["usersList"] = this.usersList;
            localStorage.setItem("org_data", JSON.stringify(temp));
            this.writeLog("User", "Updated User Profile", "Updated - " + data.name, data.id);
            resolve();
        });
    }

    getMyTodos() {
        return new Promise((resolve, reject) => {
            let res = this.todos.filter((todo) => {
                return todo.user.indexOf(this.myId) > -1;
            });
            resolve(res);
        });
    }

    getMyWorkflows() {
        return new Promise((resolve, reject) => {
            let res = this.workflows.filter((workflow) => {
                return workflow.user.indexOf(this.myId) > -1;
            });
            resolve(res);
        });
    }

    getMyOneToOnes() {
        return new Promise((resolve, reject) => {
            let res = this.oneToOnes.filter((oneToOne) => {
                return oneToOne.user.indexOf(this.myId) > -1;
            });
            resolve(res);
        });
    }


    getMyPermissions() {
        return new Promise((resolve, reject) => {
            resolve(this.myPermissions);
        });
    }

    addOneToOne(data, name) {
        return new Promise((resolve, reject) => {
            this.oneToOnes.push(data);
            let temp = JSON.parse(localStorage.org_data);
            temp["oneToOnes"] = this.oneToOnes;
            localStorage.setItem("org_data", JSON.stringify(temp));
            this.writeLog("1-1", "Initiated 1-1", "Initiated 1-1 - " + name, data.id);
            resolve();
        });
    }

    updateOneToOnes(data, activity, details, verbose, id) {
        return new Promise((resolve, reject) => {
            this.oneToOnes = data;
            let temp = JSON.parse(localStorage.org_data);
            temp["oneToOnes"] = this.oneToOnes;
            localStorage.setItem("org_data", JSON.stringify(temp));
            this.writeLog(activity, details, verbose, id);
            resolve();
        });
    }

    addTodo(data, member_name) {
        return new Promise((resolve, reject) => {
            this.todos.push(data);
            let temp = JSON.parse(localStorage.org_data);
            temp["todos"] = this.todos;
            localStorage.setItem("org_data", JSON.stringify(temp));
            this.writeLog("Todo", "Created Todo", "Created Todo - " + member_name, data.id);
            resolve();
        });
    }

    updateTodos(data, activity, details, verbose, id) {
        return new Promise((resolve, reject) => {
            this.todos = data;
            let temp = JSON.parse(localStorage.org_data);
            temp["todos"] = this.todos;
            localStorage.setItem("org_data", JSON.stringify(temp));
            this.writeLog(activity, details, verbose, id);
            resolve();
        });
    }

    updateWorkflows(data, activity, details, verbose, id) {
        return new Promise((resolve, reject) => {
            this.workflows = data;
            let temp = JSON.parse(localStorage.org_data);
            temp["workflows"] = this.workflows;
            localStorage.setItem("org_data", JSON.stringify(temp));
            this.writeLog(activity, details, verbose, id);
            resolve();
        });
    }

    addWorkflow(data, member_name) {
        return new Promise((resolve, reject) => {
            this.workflows.push(data);
            let temp = JSON.parse(localStorage.org_data);
            temp["workflows"] = this.workflows;
            localStorage.setItem("org_data", JSON.stringify(temp));
            this.writeLog("Workflow", "Created workflow", "Created Workflow - " + member_name, data.id);
            resolve();
        });
    }

    reloadServices() {
        let i, temp;
        this.myId = JSON.parse(localStorage.org_data)["myId"];
        this.myPermissions = JSON.parse(localStorage.org_data)["myPermissions"];

        this.members = JSON.parse(localStorage.org_data)["membersList"];
        for (i = 0; i < this.members.length; i += 1) {
            if (!this.members[i].archived) {
                this.members[i].archived = false;
            }
            this.members[i].dirty = false;
        }

        temp = JSON.parse(localStorage.org_data);
        temp["membersList"] = this.members;
        localStorage.setItem("org_data", JSON.stringify(temp));

        this.usersList = JSON.parse(localStorage.org_data)["usersList"];

        this.memberGenders = JSON.parse(localStorage.org_data)["memberGenders"];
        for (i = 0; i < this.memberGenders.length; i += 1) {
            if (!this.memberGenders[i].archived) {
                this.memberGenders[i].archived = false;
            }
        }

        this.memberGenders = this.memberGenders.filter((gender) => {
            return gender.archived.toString().indexOf('false')> -1;
        });

        this.memberOccupations = JSON.parse(localStorage.org_data)["memberOccupations"];
        for (i = 0; i < this.memberOccupations.length; i += 1) {
            if (!this.memberOccupations[i].archived) {
                this.memberOccupations[i].archived = false;
            }
        }

        this.memberOccupations = this.memberOccupations.filter((occupation) => {
            return occupation.archived.toString().indexOf('false')> -1;
        });

        this.memberEducations = JSON.parse(localStorage.org_data)["memberEducations"];
        for (i = 0; i < this.memberEducations.length; i += 1) {
            if (!this.memberEducations[i].archived) {
                this.memberEducations[i].archived = false;
            }
        }

        this.memberEducations = this.memberEducations.filter((education) => {
            return education.archived.toString().indexOf('false')> -1;
        });

        this.memberCustomFields = JSON.parse(localStorage.org_data)["memberCustomFields"];
        for (i = 0; i < this.memberCustomFields.length; i += 1) {
            if (!this.memberCustomFields[i].archived) {
                this.memberCustomFields[i].archived = false;
            }
        }

        this.memberCustomFields = this.memberCustomFields.filter((field) => {
            return field.archived.toString().indexOf('false')> -1;
        });

        this.locationsHierarchy = JSON.parse(localStorage.org_data)["locationsHierarchy"];
        this.levelsHierarchy = JSON.parse(localStorage.org_data)["levelsHierarchy"];

        this.todos = JSON.parse(localStorage.org_data)["todos"];
        for (i = 0; i < this.todos.length; i += 1) {
            if (!this.todos[i].archived) {
                this.todos[i].archived = false;
            }
            this.todos[i].dirty = false;
        }

        temp = JSON.parse(localStorage.org_data);
        temp["todos"] = this.todos;
        localStorage.setItem("org_data", JSON.stringify(temp));

        this.workflows = JSON.parse(localStorage.org_data)["workflows"];
        for (i = 0; i < this.workflows.length; i += 1) {
            if (!this.workflows[i].archived) {
                this.workflows[i].archived = false;
            }
            this.workflows[i].dirty = false;
        }
        
        temp = JSON.parse(localStorage.org_data);
        temp["workflows"] = this.workflows;
        localStorage.setItem("org_data", JSON.stringify(temp));

        this.oneToOnes = JSON.parse(localStorage.org_data)["oneToOnes"];
        for (i = 0; i < this.oneToOnes.length; i += 1) {
            if (!this.oneToOnes[i].archived) {
                this.oneToOnes[i].archived = false;
            }
            this.oneToOnes[i].dirty = false;
        }
        
        temp = JSON.parse(localStorage.org_data);
        temp["oneToOnes"] = this.oneToOnes;
        localStorage.setItem("org_data", JSON.stringify(temp));

        this.otherLanguages = JSON.parse(localStorage.org_data)["otherLanguages"];

        this.user = this.usersList.filter((user) => {
            return user.id.indexOf(this.myId) > -1;
        })[0];
    }


    getAllDirtyData() {
        return new Promise((resolve, reject) => {
            let org_data = {
                'membersList': [],
                'oneToOnes': [],
                'todos': [],
                'workflows': [],
                'logs': [],
                'pushLogs': []
            }

            this.members = JSON.parse(localStorage.org_data)["membersList"];

            org_data['membersList'] = this.members.filter((member) => {
                return member.dirty.toString().indexOf('true') > -1;
            });

            if (org_data['membersList'].length === 0) {
                delete org_data['membersList'];
            }

            this.oneToOnes = JSON.parse(localStorage.org_data)["oneToOnes"];

            org_data['oneToOnes'] = this.oneToOnes.filter((oneToOne) => {
                return oneToOne.dirty.toString().indexOf('true') > -1;
            });

            if (org_data['oneToOnes'].length === 0) {
                delete org_data['oneToOnes'];
            }

            this.todos = JSON.parse(localStorage.org_data)["todos"];

            org_data['todos'] = this.todos.filter((todo) => {
                return todo.dirty.toString().indexOf('true') > -1;
            });

            if (org_data['todos'].length === 0) {
                delete org_data['todos'];
            }

            this.workflows = JSON.parse(localStorage.org_data)["workflows"];

            org_data['workflows'] = this.workflows.filter((workflow) => {
                return workflow.dirty.toString().indexOf('true') > -1;
            });

            if (org_data['workflows'].length === 0) {
                delete org_data['workflows'];
            }

            this.logs = JSON.parse(localStorage.org_data)["logs"];
            this.pushLogs = JSON.parse(localStorage.org_data)["pushLogs"];
            let i;

            for (i = 0; i < this.logs.length; i += 1) {
                if (!this.logs[i].dirty) {
                    this.logs[i].dirty = false;
                }
            }
           

            for (i = 0; i < this.pushLogs.length; i += 1) {
                if (!this.pushLogs[i].dirty) {
                    this.pushLogs[i].dirty = false;
                }
            }

            if (this.logs.length > 0) {
                org_data['logs'] = this.logs.filter((log) => {
                    return log.dirty.toString().indexOf('true') > -1;
                });

                if (org_data['logs'].length === 0) {
                    delete org_data['logs'];
                }
            }

            if (this.pushLogs.length > 0) {
                org_data['pushLogs'] = this.pushLogs.filter((log) => {
                    return log.dirty.toString().indexOf('true') > -1;
                });

                if (org_data['pushLogs'].length === 0) {
                    delete org_data['pushLogs'];
                }
            }

            resolve(org_data);
            // console.log(org_data);
        });

    }

}
