import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { LanguageService } from '../services/language.service';
import { GroupService } from '../services/group.service';
import { MembersService } from '../services/members.service';
import { ToastController } from '@ionic/angular';
import { Vibration } from '@ionic-native/vibration/ngx';

@Component({
    selector: 'app-add-group-member',
    templateUrl: './add-group-member.page.html',
    styleUrls: ['./add-group-member.page.scss'],
})
export class AddGroupMemberPage implements OnInit {
    group_id = null;
    all_groups: any = [];
    group_details: any = { members: [] };
    search_member = '';
    temp_filter_results: any = [];
    temp_members: any = [];
    members: any = [];
    search_name: '';
    group_members = [];
    group_representatives = [];
    save_state = 'inactive';
    hide_on_focus = false;
    sort_action_sheet = false;

    constructor(
        public languageService: LanguageService,
        private groupService: GroupService,
        public modalController: ModalController,
        private navParams: NavParams,
        public membersService: MembersService,
        public toastController: ToastController,
        private vibration: Vibration
    ) { }

    ngOnInit() {
        this.group_id = this.navParams.get('group_id');

        this.groupService.getGroups().then((data) => {
            this.all_groups = data;

            this.group_details = this.all_groups.filter((group) => {
                return group.id.toString().indexOf(this.group_id.toString()) > -1;
            })[0];

            this.group_members = this.group_details.members;
            this.group_representatives = this.group_details.representatives;

            this.membersService.getAllMembers().then((data) => {
                this.members = data;
                this.temp_members = data;
            });

        });
    }

    back() {
        this.modalController.dismiss();
    }


    search() {
        this.temp_filter_results = this.members.filter((member) => {
            return member.name.toLowerCase().indexOf(this.search_name.toLowerCase()) > -1;
        });
    }

    toggleGroupMember(id) {
        if (this.group_members.includes(id)) {
            let index = this.group_members.indexOf(id);
            this.group_members.splice(index, 1);
        } else {
            if (this.group_members.length === 20) {
                this.presentToast("Cannot add more than 20 Members to the group");
                this.vibration.vibrate(1000);
                if (navigator.vibrate) {
                    navigator.vibrate(1000);
                }
            } else {
                this.group_members.push(id);
            }
        }
        console.log(this.group_members);
    }

    toggleGroupRepresentative(id) {
        if (this.group_representatives.includes(id)) {
            let index = this.group_representatives.indexOf(id);
            this.group_representatives.splice(index, 1);
        } else {
            this.group_representatives.push(id);
        }
        console.log(this.group_representatives);
    }

    isRep(id) {
        if (this.group_representatives.includes(id)) {
            return true;
        } else {
            return false;
        }
    }


    isMem(id) {
        if (this.group_members.includes(id)) {
            return true;
        } else {
            return false;
        }
    }


    save() {
        this.groupService.addGroupRepresentatives(this.group_details.id, this.group_representatives);
        this.groupService.addGroupMembers(this.group_details.id, this.group_members);
        this.back();
    }

    sortBy(param) {
        if (param === null) {
            this.temp_members = JSON.parse(JSON.stringify(this.members));
        }

        if (param === 'name') {
            this.temp_members.sort(function(a, b){
                if(a.name < b.name) { return -1; }
                if(a.name > b.name) { return 1; }
                return 0;
            });
        }
    }

    unSort() {
        this.temp_members = JSON.parse(JSON.stringify(this.members));
    }

    searchFilter() {
        this.temp_members = this.members.filter((member) => {
            return member.name.toLowerCase().indexOf(this.search_member) > -1;
        });
    }

    async presentToast(msg) {
        const toast = await this.toastController.create({
            message: msg,
            duration: 4500
        });
        toast.present();
    }

}
