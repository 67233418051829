import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html'
})
export class AppComponent {
    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        public router: Router
    ) {
        this.initializeApp();
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();
        });

        if (!localStorage.language) {
            localStorage.language = 'english';
        }

        if (!localStorage.last_active) {
            localStorage.last_active = new Date().getDate();
        }

        if (localStorage.last_active !== new Date().getDate().toString()) {
            this.router.navigate(['']);
        } else if (localStorage.login_token) {
            this.router.navigate(['/tabs/dashboard']);
        } else {
            this.router.navigate(['']);
        }

        if (localStorage.org_data) {
            let data = JSON.parse(localStorage.org_data)["orgData"];
            let root = document.documentElement;
            root.style.setProperty('--indigo', data.primary_color);
            root.style.setProperty('--pink', data.contrast_color);
        }

    }
}
