import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Vibration } from '@ionic-native/vibration/ngx';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AddGroupMemberPageModule } from './add-group-member/add-group-member.module';
import { environment } from '../environments/environment';

import { HideOnFocusService } from './services/hide-on-focus.service'
import { MembersService } from './services/members.service';
import { WorkflowsService } from './services/workflows.service';
import { TodosService } from './services/todos.service';
import { QuestionsService } from './services/questions.service';
import { FilterService } from './services/filter.service';
import { LanguageService } from './services/language.service';
import { LogService } from './services/log.service';
import { UpdateService } from './update.service';
import { GroupService } from './services/group.service';
import { HttpModule } from '@angular/http';

import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule,
      IonicModule.forRoot(),
      HttpModule,
      AppRoutingModule,
      AddGroupMemberPageModule,
      ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    HideOnFocusService,
    MembersService,
    WorkflowsService,
    TodosService,
    QuestionsService,
    FilterService,
    LanguageService,
    LogService,
    UpdateService,
    SocialSharing,
    Geolocation,
    Vibration
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
