import {
    Injectable
} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LogService {
    logs: any;
    pushLogs: any;
    myId: any;

    constructor() {
        let i;
        this.myId = JSON.parse(localStorage.org_data)["myId"];
        this.logs = JSON.parse(localStorage.org_data)["logs"];
        for (i = 0; i < this.logs.length; i += 1) {
            if (!this.logs[i].archived) {
                this.logs[i].archived = false;
            }
            this.logs[i].dirty = false;
        }
        this.pushLogs = JSON.parse(localStorage.org_data)["pushLogs"];
        for (i = 0; i < this.pushLogs.length; i += 1) {
            if (!this.pushLogs[i].archived) {
                this.pushLogs[i].archived = false;
            }
            this.pushLogs[i].dirty = false;
        }
    }

    getPushLog() {
        return new Promise((resolve, reject) => {
            let res = this.pushLogs.reverse();
            res = res.filter((res) => {
                return res.user.indexOf(this.myId) > -1;
            });
            resolve(res);
        });
    }

    addPushLog(data) {
        return new Promise((resolve, reject) => {
            this.pushLogs.push(data);
            let temp = JSON.parse(localStorage.org_data);
            temp["pushLogs"] = this.pushLogs;
            localStorage.setItem("org_data", JSON.stringify(temp));
            resolve();
        });
    }

    getActivities() {
        return new Promise((resolve, reject) => {
            let res = this.logs;
            res = res.filter((res) => {
                return res.user.indexOf(this.myId) > -1;
            });
            resolve(res.reverse());
        });
    }

    addLog(data) {
        return new Promise((resolve, reject) => {
            this.logs.push(data);
            let temp = JSON.parse(localStorage.org_data);
            temp["logs"] = this.logs;
            localStorage.setItem("org_data", JSON.stringify(temp));
            resolve();
        });
    }

    reloadLogs() {
        let i;
        this.myId = JSON.parse(localStorage.org_data)["myId"];
        this.logs = JSON.parse(localStorage.org_data)["logs"];        

        for (i = 0; i < this.logs.length; i += 1) {
            if (!this.logs[i].archived) {
                this.logs[i].archived = false;
            }
            this.logs[i].dirty = false;
        }

        this.pushLogs = JSON.parse(localStorage.org_data)["pushLogs"];
        
        for (i = 0; i < this.pushLogs.length; i += 1) {
            if (!this.pushLogs[i].archived) {
                this.pushLogs[i].archived = false;
            }
            this.pushLogs[i].dirty = false;
        }

        let temp = JSON.parse(localStorage.org_data);
        temp["logs"] = this.logs;
        temp["pushLogs"] = this.pushLogs;
        localStorage.setItem("org_data", JSON.stringify(temp));

    }

}
