import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class HideOnFocusService {

    constructor() { }


    toggleTabsOnInput() {
        var i, inputs = document.querySelectorAll('input, textarea');
        for(i = 0; i < inputs.length; i += 1) {
            inputs[i].addEventListener('focus', () => {
                this.hideTab();
            });
        }

        for(i = 0; i < inputs.length; i += 1) {
            inputs[i].addEventListener('blur', () => {
                this.showTab();
            });
        }
    }

    hideTab() {
        if (document.querySelector('ion-tab-bar')) {
            document.querySelector('ion-tab-bar').style.display = "none";
        }
    }

    showTab() {
        if (document.querySelector('ion-tab-bar')) {
            document.querySelector('ion-tab-bar').style.display = "flex";
        }
    }


    toggleStickyButtonsOnInput(class_name) {
        var i, inputs = document.querySelectorAll('input, textarea');
        for(i = 0; i < inputs.length; i += 1) {
            inputs[i].addEventListener('focus', () => {
                this.hideButton(class_name);
            });
        }

        for(i = 0; i < inputs.length; i += 1) {
            inputs[i].addEventListener('blur', () => {
                this.showButton(class_name);
            });
        }
    }

    hideButton(class_name) {
        var i, class_elements = document.querySelectorAll(class_name);
        for(i = 0; i < class_elements.length; i += 1) {
            class_elements[i].style.position = "static";
        }
    }

    showButton(class_name) {
        var i, class_elements = document.querySelectorAll(class_name);
        for(i = 0; i < class_elements.length; i += 1) {
            class_elements[i].style.position = "sticky";
        }
    }

}
