import { Injectable } from '@angular/core';
import { MembersService } from './members.service';
import { TodosService } from './todos.service';
import { WorkflowsService } from './workflows.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class FilterService {
    members: any;
    todos: any;
    workflows: any;
    recently_searched: any = [];

    filter_results = {
        members : [],
        todos: [],
        workflows: []
    };

    constructor(
        public membersService: MembersService,
        public todosService: TodosService,
        public workflowsService: WorkflowsService,
        public router: Router
    ) {
        this.membersService.getAllMembers().then((member_data) => {
            this.members = member_data;
            let not_generated = "00000000";
            for (let i = 0; i < this.members.length; i += 1) {
                if (!this.members[i].member_id) {
                    this.members[i].member_id = not_generated;
                } else {
                    this.members[i].member_id = (this.members[i].member_id).toString().padStart(8, 0);
                }
            }
        });
        this.workflowsService.getAllWorkflows().then((workflow_data) => {
            this.workflows = workflow_data;
        });
        this.todosService.getAllTodos().then((todo_data) => {
            this.todos = todo_data;
        });
        if (localStorage.recently_searched) {
            this.recently_searched = JSON.parse(localStorage.recently_searched);
        }
    }

    reload() {
        this.membersService.getAllMembers().then((member_data) => {
            this.members = member_data;
            let not_generated = "00000000";
            for (let i = 0; i < this.members.length; i += 1) {
                if (!this.members[i].member_id) {
                    this.members[i].member_id = not_generated;
                } else {
                    this.members[i].member_id = (this.members[i].member_id).toString().padStart(8, 0);
                }
            }
        });
        this.workflowsService.getAllWorkflows().then((workflow_data) => {
            this.workflows = workflow_data;
        });
        this.todosService.getAllTodos().then((todo_data) => {
            this.todos = todo_data;
        });
        if (localStorage.recently_searched) {
            this.recently_searched = JSON.parse(localStorage.recently_searched);
        }
    }

    addToRecents(name) {
        if (this.recently_searched.indexOf(name) < 0) {
            this.recently_searched.push(name);
            localStorage.recently_searched = JSON.stringify(this.recently_searched);
        } else {
            console.log("Already present");
        }
    }

    getRecentlySearched() {
        return new Promise((resolve, reject) => {
            let res = this.recently_searched.reverse();
            resolve(res);
        });
    }

    filterSearchTerms(searchTerm) {
        this.filter_results.members = this.members.filter((member) => {
            return member.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
            member.member_id.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
            member.phoneNumber.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
        });

        this.filter_results.workflows = this.workflows.filter((workflow) => {
            return workflow.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
        });

        this.filter_results.todos = this.todos.filter((todo) => {
            return todo.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
        });

        return this.filter_results;

    }

    startWorkflow(workflow) {
        this.router.navigate(['/assign-workflow', { workflow: JSON.stringify(workflow) }]);
    }

    startTodo(todo) {
        this.router.navigate(['/init-todo', { todo: JSON.stringify(todo) }]);
    }

    memberProfile(id) {
        this.router.navigate(['/member-profile', { id : id }]);
    }
}
