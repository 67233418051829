import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    { path: '', loadChildren: './login/login.module#LoginPageModule' },
    { path: '', loadChildren: './tabs/tabs.module#TabsPageModule' },
    { path: 'init-one-one', loadChildren: './initiate-one-one/initiate-one-one.module#InitiateOneOnePageModule' },
    { path: 'ask-question/:one_to_one/:member', loadChildren: './questionnaire/questionnaire.module#QuestionnairePageModule' },
    { path: 'member-profile', loadChildren: './member-profile/member-profile.module#MemberProfilePageModule' },
    { path: 'add-member/:member_id',  loadChildren: './add-member/add-member.module#AddMemberPageModule' },
    { path: 'add-member',  loadChildren: './add-member/add-member.module#AddMemberPageModule' },
    { path: 'assign-workflow',  loadChildren: './assign-workflow/assign-workflow.module#AssignWorkflowPageModule' },
    { path: 'init-todo',  loadChildren: './initiate-todo/initiate-todo.module#InitiateTodoPageModule' },
    { path: 'add-workflow',  loadChildren: './add-workflow/add-workflow.module#AddWorkflowPageModule' },
    { path: 'add-todo',  loadChildren: './add-todo/add-todo.module#AddTodoPageModule' },
    { path: 'change-password',  loadChildren: './change-password/change-password.module#ChangePasswordPageModule' },
    { path: 'language',  loadChildren: './language/language.module#LanguagePageModule' },
    { path: 'push-data-log',  loadChildren: './push-data-log/push-data-log.module#PushDataLogPageModule' },
    { path: 'edit-profile',  loadChildren: './edit-profile/edit-profile.module#EditProfilePageModule' },
    { path: 'near',  loadChildren: './near/near.module#NearPageModule' },
    { path: 'activity-log',  loadChildren: './activity-log/activity-log.module#ActivityLogPageModule' },
    { path: 'themes',  loadChildren: './themes/themes.module#ThemesPageModule' },
    { path: 'contact',  loadChildren: './contact/contact.module#ContactPageModule' },
    { path: 'forgot-password',  loadChildren: './forgot-password/forgot-password.module#ForgotPasswordPageModule' },
    { path: 'add-group', loadChildren: './add-group/add-group.module#AddGroupPageModule' }
];
@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
