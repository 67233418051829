import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TodosService {
    todos: any;

    constructor() {
        this.todos = JSON.parse(localStorage.org_data)["todosList"];
        let i;
        for (i = 0; i < this.todos.length; i += 1) {
            if (!this.todos[i].archived) {
                this.todos[i].archived = false;
            }
        }
        this.todos = this.todos.filter((todo) => {
            return todo.archived.toString().indexOf('false')> -1;
        });
    }

    getAllTodos() {
        return new Promise((resolve, reject) => {
            resolve(this.todos);
        });
    }

    getTodoById(id: string) {
        return new Promise((resolve, reject) => {
            let i = 0;
            for (i = 0; i < this.todos.length; i += 1) {
                if (id === this.todos[i].id) {
                    resolve(this.todos[i]);
                }
            }
        });
    }

    reloadTodos() {
        this.todos = JSON.parse(localStorage.org_data)["todosList"];
        let i;
        for (i = 0; i < this.todos.length; i += 1) {
            if (!this.todos[i].archived) {
                this.todos[i].archived = false;
            }
        }
        this.todos = this.todos.filter((todo) => {
            return todo.archived.toString().indexOf('false')> -1;
        });
    }
}
