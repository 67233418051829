import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class GroupService {
    all_groups: any = [{
        id: '000001',
        name: 'GP1',
        type: 'Type1',
        language: 'Eng',
        nationality: 'Ind',
        state: 'Kar',
        district: 'Ben',
        site: 'Jay',
        members: [],
        representatives: [],
        archived: false
    }];

    constructor() {

    }

    getGroups() {
        return new Promise((resolve, reject) => {
            resolve(this.all_groups);
        });
    }

    addGroup(data) {
        return new Promise((resolve, reject) => {
            this.all_groups.push(data);
            resolve(this.all_groups);
        });
    }

    editGroup(data) {
        return new Promise((resolve, reject) => {
            for (let i = 0; i < this.all_groups.length; i += 1) {
                if (this.all_groups[i].id === data.id) {
                    this.all_groups[i] = data;
                }
            }
            resolve(this.all_groups);
        });
    }

    addGroupRepresentatives(id, data) {
        return new Promise((resolve, reject) => {
            for (let i = 0; i < this.all_groups.length; i += 1) {
                if (this.all_groups[i].id === id) {
                    this.all_groups[i].representatives = data;
                }
            }
            resolve(this.all_groups);
        });
    }

    addGroupMembers(id, data) {
        return new Promise((resolve, reject) => {
            for (let i = 0; i < this.all_groups.length; i += 1) {
                if (this.all_groups[i].id === id) {
                    this.all_groups[i].members = data;
                }
            }
            resolve(this.all_groups);
        });
    }
}
