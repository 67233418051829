import {
    Injectable
} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    translations: any = {};
    otherLanguages: any = [];

    constructor() {
        this.translations = JSON.parse(localStorage.org_data)["translations"];
        this.otherLanguages = JSON.parse(localStorage.org_data)["otherLanguages"];        
    }

    getLangDict(name: string) {        
        if (localStorage.language !== 'english') {
            if (this.translations[localStorage.language][name]) {
                return this.translations[localStorage.language][name];
            } else {
                return name;
            }
        } else {
            return name;
        }
    }

    getLanguages() {
        return new Promise((resolve, reject) => {
            resolve(this.otherLanguages);
        });
    }

    reloadLanguages() {
        this.translations = JSON.parse(localStorage.org_data)["translations"];
        this.otherLanguages = JSON.parse(localStorage.org_data)["otherLanguages"];
    }

}
